.App {
	text-align: center;
}
.error{
	color: red;
	font-weight: bold;	
}

body {
	background: #eeeeee !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #f1f1ff inherit;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 5px;
	height: 8px;
}

*::-webkit-scrollbar-track {
	background: inherit;
}

*::-webkit-scrollbar-thumb {
	background: #bfbfbf !important;
	border-radius: 20px;
}

#reload_href {
	visibility: hidden;
	display: none;
}

.swal2-container {
	z-index: 1201 !important;
}

#drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
