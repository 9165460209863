body {
	margin: 0;
	font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"), url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Roboto";
	font-weight: 900;
	src: local("Roboto"), url(./fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Roboto";
	font-weight: 900;
	src: local("Roboto"), url(./fonts/Roboto-Black.ttf) format("truetype");
}

@font-face {
	font-family: "Roboto";
	font-weight: 500;
	src: local("Roboto"), url(./fonts/Roboto-Medium.ttf) format("truetype");
}
